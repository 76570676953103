
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      event: 'event/event'
    })
  },
  methods: {}
}
