
import { mapGetters } from 'vuex'
export default {
  props: {
    buttonLink: {
      type: String,
      default: 'widget'
    },
    buttonText: {
      type: String,
      default: 'misc.buy_ticket'
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      total: 'cart/total',
      count: 'cart/count',
      currency: 'cart/currency',
      paymentPage: 'router/paymentPage'
    })
  },
  methods: {
    navigate() {
      this.$router.push(
        this.localePath({
          name: this.buttonLink
        })
      )
    }
  }
}
