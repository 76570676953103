
export default {
  name: 'PagesHome',
  layout({ store }) {
    if (store.getters['cookies/isWidget']) {
      return 'widget'
    }

    return 'default'
  },
  async asyncData({ params, error, app, $config }) {
    try {
      if ($config.EVENT_GROUP_ID) {
        const group = await app.$axios.$get(
          '/api/eventgroup/' + $config.EVENT_GROUP_ID
        )
        return {
          group
        }
      }
    } catch (e) {
      return error({
        statusCode: 404
      })
    }
  }
}
